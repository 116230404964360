<template>
    <div :style="{backgroundColor: $route.query.backgroundColor ||'#eee'}" class="container prow-40">
        <h1 class="title pt-20 pb-10">《儿童隐私保护声明》</h1>
        <div class="f-fs-fe f-col date">
            <span>生效发布日期：2021年08月16日 </span>
            <span>更新日期：2021年08月16日 </span>
        </div>
        <div class="ti-1 pre-txt mt-10">
            幼享乐致力于保护使用我们产品或服务的儿童的个人信息。《儿童隐私保护声明》（以下简称“本声明”）和《隐私政策》旨在向监护人（以下简称“您”）和儿童说明我们如何收集、使用、存储和处理儿童的个人信息，以及我们为您与儿童提供的访问、更正、删除和保护这些信息的方式。除另有约定外，本声明所用术语和缩略词与《用户服务协议》、《隐私政策》中的术语和缩略词具有相同的涵义。
        </div>
        <div class="txt">
            <h2 class="ul-title pt-20 pb-10">儿童监护人特别说明：</h2>
            <p class="ti-1">如果您是儿童用户的监护人，请您仔细阅读和选择是否同意本声明。我们希望请您与我们共同保护儿童的个人信息，教育引导儿童增强个人信息保护意识和能力，指导、提醒和要求他们在未经您同意的情况下，不向任何网络产品和服务提供者提供任何个人信息。</p>



            <h2 class="ul-title pt-20 pb-10">儿童特别说明：</h2>
            <div class="mrow-10">
                <div class="ti-1">
                    如果你是14周岁以下的未成年人，你需要和你的监护人一起仔细阅读本声明，并在征得你的监护人同意后，使用我们的产品、服务或向我们提供信息。
                    请您在仔细阅读、充分理解《隐私政策》和本声明后，选择是否同意相关隐私政策以及是否同意您和您监护的儿童使用我们的产品或服务。
                </div>
                <div class="ti-1">如果您不同意相关隐私政策和本声明的内容或不同意提供服务所必要的信息，将可能导致我们的产品或服务无法正常运行，或者无法达到我们拟达到的服务效果，您和您监护的儿童可能无法正常使用我们的产品、服务或相关的具体业务功能。</div>
                <div class="ti-1">您点击“同意”即表示您同意我们按照《隐私政策》和本声明的约定收集、使用和处理您和您监护的儿童的信息。我们提醒您，本声明中与您和您监护的儿童的权益（可能）密切相关的重要条款，我们已采用加粗字体来特别提醒您，请您重点查阅。</div>
                <div class="ti-1">如您在阅读本声明过程中有任何疑问或权利请求等个人信息相关事宜的，我们为您提供了专门的反馈渠道，具体参见本声明“联系我们”章节，我们会尽快为您作出解答。</div>
                <div class="ti-1">一旦开始使用广东柏斯融媒体科技有限公司幼享乐旗下产品及服务，即表示儿童和其监护人已经充分理解儿童并同意本政策。如果儿童或监护人不同意本政策，请立即停止使用广东柏斯融媒体科技有限公司幼享乐旗下产品及服务。</div>
                <div class="ti-1">
                    以下隐私政策正文将帮助儿童和其监护人了解以下内容：
                    <div class="ml-10">
                        <div class="ti-1">一、我们收集那些信息</div>
                        <div class="ti-1">二、我们如何使用儿童的信息</div>
                        <div class="ti-1">三、我们如何保存儿童的信息</div>
                        <div class="ti-1">四、信息共享、转移、披露儿童个人信息的情形</div>
                        <div class="ti-1">五、如何管理儿童的信息</div>
                        <div class="ti-1">六、适用法律</div>
                        <div class="ti-1">七、政策更新</div>
                        <div class="ti-1">八、联系我们</div>
                    </div>
                </div>



                <h2 class="ul-title pt-20 pb-10">一、我们收集哪些信息</h2>
                <p class="ti-1">幼享乐严格遵守法律法规的规定及与用户的约定，根据用户选择产品或服务的具体情况，收集您和您监护的儿童的以下个人信息。</p>
                <p class="ti-1">针对我们专门面向14周岁以下的儿童的产品和服务或产品和服务的部分功能板块，或需要用户输入生日、年龄信息等能识别用户年龄的产品或服务，如注册或使用这类产品或服务，我们会要求用户填写儿童姓名、昵称、性别、生日、人脸照片、学校名称、班级信息等，以识别该用户是否为14周岁以下的儿童，并按照法律法规的规定，在获得监护人同意之后才允许儿童继续使用相关产品或服务。</p>
                <p class="ti-1">
                    当我们根据用户提供的上述信息识别出用户为14周岁以下的儿童时，我们会收集您的联系方式，包括手机号码，并使用这些信息与您联系，验证您与您监护的儿童的监护关系。根据产品或服务验证监护关系、征得您同意的具体方案，我们会将您的账号与儿童信息进行关联，以便保护您和您监护的儿童的个人信息权益。在某些涉及交易的产品或服务中，我们可能会要求收集和绑定您的银行卡相关信息，以便您知悉和管理儿童用户的交易行为。
                    如我们需要超出上述收集范围收集您或您监护的儿童的个人信息，我们将再次告知您，并征得您的同意。
                </p>
                <p class="ti-1">请您理解，您可以选择是否填写或向我们提供特定的信息，但您如果不填写或提供某些特定的信息，将可能导致我们的产品或服务无法正常运行，或者无法达到我们拟达到的服务效果，您和您监护的儿童可能无法正常使用我们的产品、服务或相关的具体业务功能。</p>
                <p class="ti-1">我们会收取</p>



                <h2 class="ul-title pt-20 pb-10">二、我们如何使用这些信息</h2>
                <div class="ti-1">我们严格遵守法律法规的规定和与用户的约定，将收集的您和您所监护的儿童的信息用于以下用途：</div>
                <div class="ti-1">
                    <div class="ml-10">
                        <div class="ti-1">a、验证儿童和监护人身份</div>
                        <div class="ti-1">b、提供必要的产品或服务、产品或服务的具体功能</div>
                        <div class="ti-1">c、用于优化产品或服务、安全保障、服务调查目的</div>
                    </div>
                </div>
                <div class="ti-1">如果我们需要超出上述用途使用您或您监护的儿童的个人信息，我们将再次告知您并征得您的同意。</div>



                <h2 class="ul-title pt-20 pb-10">三、存储儿童个人信息</h2>
                <div class="ti-1">
                    <div class="ti-1">存储地点</div>
                    <div class="ml-10">
                        <div class="ti-1">我们遵守法律法规的规定，将境内收集的用户个人信息存储于境内。</div>
                    </div>
                    <div class="ti-1">存储期限</div>
                    <div class="ml-10">
                        <div class="ti-1">
                            当我们的产品或服务发生停止运营的情形或您和儿童的个人信息存储期限届满时，我们将采取例如推送通知、公告等形式通知您和儿童，并在合理的期限内删除或匿名化处理您和儿童的个人信息。
                        </div>
                    </div>
                    <div class="ti-1">信息安全</div>
                    <div class="ml-10">
                        <div class="ti-1">a.我们为您的信息提供相应的安全保障，以防止信息的丢失、不当使用、未经授权访问或披露。</div>
                        <div class="ti-1">b.我们严格遵守法律法规保护用户的通信秘密。</div>
                        <div class="ti-1">c.我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。例如，我们使用加密技术（例如，TLS、SSL）、匿名化处理等手段来保护您的个人信息。
                        </div>
                        <div class="ti-1">d.我们建立专门的管理制度、流程和组织确保信息安全。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审查。</div>
                        <div class="ti-1">e.若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并以推送通知、公告等形式告知您。</div>
                    </div>
                </div>



                <h2 class="ul-title pt-20 pb-10">四、信息共享、转移、披露儿童个人信息</h2>
                <div class="ti-1">我们遵照法律法规的规定，严格限制共享、转移、披露儿童个人信息的情形。一般情况下，幼享乐不会向第三方分享儿童的个人信息。我们仅在监护人事先同意的情况下，才可能与第三方分享儿童的个人信息。</div>
                <div class="ti-1">
                    <div class="ti-1">1、与监护人分享</div>
                    <div class="ml-10">
                        <div class="ti-1">我们会将部分儿童个人信息（如儿童用户名、帐号、密码，以及该儿童用户在我们某些产品和服务中的交易内容等）与监护人进行分享，监护人可以访问和管理相关个人信息。仅为实现外部处理的目的，我们可能会与第三方合作伙伴（第三方服务供应商、承包商、代理、应用开发者等，例如，代表我们发出电子邮件或推送通知的通讯服务提供商、为我们提供位置服务的地图服务供应商）（他们可能并非位于您所在的法域）分享儿童的个人信息，让他们按照我们的说明、隐私政策以及相关的保密和安全政策来为我们处理上述信息。如我们委托第三方处理儿童个人信息的，我们会对受委托方及委托行为等进行安全评估，签署委托协议，要求受委托方的处理行为不得超出我们的授权范围，我们委托第三方处理儿童个人信息主要用于以下用途：</div>
                        <div class="ti-1">a、向儿童提供我们的服务；</div>
                        <div class="ti-1">b、实现“我们如何使用这些信息”部分所述目的；</div>
                        <div class="ti-1">c、履行我们在《隐私政策》和本声明中的义务和行使我们的权利；</div>
                        <div class="ti-1">d、理解、维护和改善我们的服务。</div>
                        <div class="ti-1">如我们与上述第三方分享您和儿童的信息，我们将进行安全评估并采用加密、匿名化、去标识化处理等手段保障您和儿童的信息安全。</div>
                        <div class="ti-1"></div>
                        <div class="ti-1"></div>
                    </div>
                    <div class="ti-1">2、随着我们业务的持续发展，当发生合并、收购、资产转让等交易导致向第三方分享您和儿童的个人信息时，我们将通过推送通知、公告等形式告知您相关情形，按照法律法规及不低于本声明所要求的标准继续保护或要求新的管理者继续保护您和儿童的个人信息。</div>
                    <div class="ml-10">
                        <div class="ti-1">我们可能会将所收集到的儿童信息在进行匿名化处理后用于大数据分析，如形成不包含任何个人信息的热力图、洞察报告等。</div>
                        <div class="ti-1">我们可能对外公开并与我们的合作伙伴分享经统计加工后不含身份识别内容的信息，用于了解用户如何使用我们服务或让公众了解我们服务的总体使用趋势。</div>
                        <div class="ti-1">一般情况下，我们禁止对外披露儿童个人信息，但我们可能基于以下目的披露儿童的个人信息：</div>
                        <div class="ti-1">a、遵守适用的法律法规等有关规定；</div>
                        <div class="ti-1">b、遵守法院判决、裁定或其他法律程序的规定；</div>
                        <div class="ti-1">c、遵守相关政府机关或其他法定授权组织的要求；</div>
                        <div class="ti-1">d、我们有理由确信需要遵守法律法规等有关规定；</div>
                        <div class="ti-1">e、为执行相关服务协议或本声明、维护社会公共利益，为保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益合理且必要的用途。</div>
                    </div>
                </div>



                <h2 class="ul-title pt-20 pb-10">五、如何管理儿童个人信息</h2>
                <div class="ti-1">
                    <div class="ti-1">在儿童用户使用幼享乐产品或服务期间，为了您和儿童可以更加便捷地访问、更正、删除相关个人信息，我们在产品设计中为您提供了相应的操作设置，您也可以通过我们提供的联系方式，来反馈和咨询相关信息。我们会在核实您反馈的问题后及时与您联系。请您理解，在核实过程中，我们可能需要您提供帐号、儿童姓名、联系方式等个人信息，以便在完成身份验证和问题核实后继续响应您的需求。</div>
                    <div class="ti-1">1、访问儿童个人信息</div>
                    <div class="ml-10">
                        <div class="ti-1">您和您监护的儿童可以查看儿童用户在使用我们产品或服务中提供或产生的个人信息，包括但不限于用户名、密码、头像、昵称、性别、主动发布的内容等。</div>
                    </div>
                    <div class="ti-1">2、更正儿童个人信息</div>
                    <div class="ml-10">
                        <div class="ti-1">当您和您监护的儿童发现我们收集、使用和处理的儿童个人信息有错误时，可以联系我们进行更正。我们会在完成身份验证和核实问题后，及时采取措施予以更正。</div>
                    </div>
                    <div class="ti-1">3、删除儿童个人信息</div>
                    <div class="ml-10">
                        <div class="ti-1">如存在以下情况，您和您监护的儿童可以请求我们删除收集、使用和处理的儿童个人信息。我们会在完成身份验证和核实问题后，及时采取措施予以删除。</div>
                        <div class="ti-1">(1)若我们违反法律、行政法规的规定或者双方的约定收集、存储、使用、转移、披露儿童个人信息；</div>
                        <div class="ti-1">(2)若我们超出目的范围或者必要期限收集、存储、使用、转移、披露儿童个人信息；</div>
                        <div class="ti-1">(3)您撤回同意</div>
                        <div class="ti-1">(4)您或您监护的儿童通过注销等方式终止使用产品或者服务的。</div>
                        <div class="ti-1">但请注意，若您和被监护儿童要求我们删除特定儿童个人信息，可能导致该儿童用户无法继续使用我们的产品和服务或产品和服务中的某些具体业务功能。 如您发现在未事先征得您同意的情况下收集了您儿童的个人信息，请及时联系我们，我们会设法尽快删除相关数据。</div>
                    </div>
                    <div class="ti-1">4、儿童何时可以自己管理帐号</div>
                    <div class="ml-10">
                        <div class="ti-1">被监护儿童年满14周岁后，有权自行管理其帐号。如果儿童用户选择自行管理其幼享乐帐号，您将无法继续访问或控制其帐号。当被监护儿童有权自行管理幼享乐帐号时，我们会通知您和被监护儿童。</div>
                    </div>
                </div>



                <h2 class="ul-title pt-20 pb-10">六、适用法律</h2>
                <div class="ti-1">请您理解，本声明，包括但不限于本声明的订立、效力、解释、履行和争议解决，均应适用于中华人民共和国法律（不包括香港特别行政区、澳门特别行政区和台湾地区法律）。</div>

                <h2 class="ul-title pt-20 pb-10">七、政策更新</h2>
                <div class="ti-1">儿童和其监护人理解并同意，我们根据法律法规或者我们的商业决策更新本政策，儿童和其监护人愿意受修订后政策的约束。如果涉及本政策重大变更，我们将以公告或者信息推送方式作出通知。</div>

                <h2 class="ul-title pt-20 pb-10">八、联系我们</h2>
                <div class="ti-1">当你有其他的投诉、建议、未成年人个人信息相关问题时，请通过youxaingle.cn与我们联系。你也可以将你的问题发送至youxiangle202163@163.com或寄到如下地址</div>
                <div class="ml-10">
                    <div class="ti-1">广东省肇庆市端州区古塔中路23号中小企业服务中心东楼3楼302室</div>
                    <div class="ti-1">邮编：526000</div>
                    <div class="ti-1">电话：0758-2312683</div>
                    <div class="ti-1">我们将尽快审核所涉问题，并在验证你的用户身份后的十五天内予以回复。</div>
                </div>
            </div>
        </div>

        <div class="ti-1 pre-txt mt-10">&nbsp;</div>
        <div class="ti-1 pre-txt mt-10">
            <a href="/yxl-web/app/launch-app?backgroundColor=white">返回</a>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ChildPrivacyStatement'
};



</script>

<style lang="scss" scoped>
.title,
.txt {
  font-size: 0.28rem;
  color: #0a1738;
  line-height: 0.4rem;
  letter-spacing: 0.02rem;

  .ul-title {
    font-size: 0.28rem;
    font-weight: bold;
  }
  .li-title {
    font-size: 0.26rem;
  }
}
.title {
  text-align: center;
  font-weight: bold;
}
.date {
  font-size: 0.2rem;
}
.ti-1 {
  text-indent: 1em;
}
.pre-txt {
  font-size: 0.24rem;
}
.fw-b {
  font-weight: bold;
}



</style>
